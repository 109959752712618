import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { WebSocketService } from "app/services/web-socket.service";
import { MessagesService } from "app/services/messages.service";
import { WebSocketMessage } from "app/models/webSocketMessage";
import { InteractionService } from "app/services/interaction.service";
import { LanguageService } from "app/services/language.service";
import { WebRTCService } from "app/services/web-rtc.service";

@Component({
  selector: 'app-avatar-conversation-board',
  templateUrl: './avatar-conversation-board.component.html',
  styleUrl: './avatar-conversation-board.component.scss'
})
export class AvatarConversationBoardComponent extends ComponentAbstract implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

    assistantName: string = 'Exafy Avatar';
    isLoadingShown: boolean = false;
    isVisible: boolean = false;
    isBlured: boolean = false;
    avatarIcon: any;

    visibilitySubscription!: Subscription;
    loadingBarSubscription!: Subscription;
    socketMessageSubscription!: Subscription
    videoLoadedSubscription!: Subscription;
    iconSubscription!: Subscription;

    private queueResponseMessage!: WebSocketMessage

    constructor(
        config: ConfigService,
        private socket: WebSocketService,
        private visibility: AvatarInteractionService,
        private message: MessagesService,
        private changeDetector: ChangeDetectorRef,
        private interaction: InteractionService,
        private language: LanguageService,
        private webRtc: WebRTCService
    ) {
        super(config);
        this.assistantName = this.config.getAgentName();
    }

    ngAfterViewInit(): void {
        this.scrollToBottom();
    }

    ngOnInit(): void {
        this.iconSubscription = this.config.getAvatarIcon().subscribe(icon => {
            if (icon) { this.avatarIcon = icon; }
        });

        this.config.scrollToBottomEvent.subscribe(() => {
            this.scrollToBottom();
        });

        this.config.blurEfectEvent.subscribe(isBlured => {
            this.isBlured = isBlured
        });

        this.visibilitySubscription = this
            .visibility
            .getVisibility('avatar-conversation-board').subscribe(visible => {
                this.isVisible = visible;
                if (this.isVisible) {
                    ["avatar-shortcuts", "avatar-loading-audio", "avatar-drag-drop",
                        "avatar-message-on-audio"].map(key => this.visibility.hideComponent(key));
                } else {
                    this.visibility.showComponent("avatar-shortcuts");
                }
            });

        this.loadingBarSubscription = this.visibility.getVisibility('avatar-loading').subscribe(visible => {
                this.isLoadingShown = visible;
            });

        this.socketMessageSubscription = this.socket.message
            .subscribe(message => {
                if (message.type === "transcription") {
                    return;
                }

                if (this.language.getSelectedLanguage().locale !== message.language){
                    this.language.setLanguage(message.language);
                }

                if (!message.audioAnswer) {
                    this.message.setMessage("ai", message);
                } else {
                    this.setQueueMessage(message);
                    this.webRtc.createTalkStream(message.content, this.config.getVoiceId()).then();
                }
            });

        this.videoLoadedSubscription = this.webRtc.onVideoLoaded.subscribe((loaded) => {
            if (loaded) {
                this.message.setVoiceMessage("ai", this.getQueueMessage());
                this.visibility.hideComponent("avatar-loading-audio");
                this.visibility.showComponent("avatar-message-on-audio");
            }
        });

        combineLatest([this.interaction.isVideoPlaying, this.visibility.getVisibility('avatar-loading-audio'), this.visibility.getVisibility('avatar-loading')])
            .subscribe(([isVideoPlaying, isLoading, isLoadingShown]) => {
                this.interaction.setInteractionMode(!(isVideoPlaying || isLoading || isLoadingShown));
            });

        this.message.setWelcomeMessage(this.language.getSelectedLanguage().locale)
        this.scrollToBottom();
    }

    // setters
    setQueueMessage (message: WebSocketMessage) {
        this.queueResponseMessage = message;
    }

    // getters
    getQueueMessage (): WebSocketMessage {
        return this.queueResponseMessage;
    }

    scrollToBottom () {
        this.changeDetector.detectChanges();
        if (this.scrollContainer) {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    ngOnDestroy () {
        this.visibilitySubscription.unsubscribe();
        this.loadingBarSubscription.unsubscribe();
        this.videoLoadedSubscription.unsubscribe();
        this.socketMessageSubscription.unsubscribe();
        this.iconSubscription.unsubscribe();
    }
}
