import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { InteractionService } from "app/services/interaction.service";
import { LanguageService } from "app/services/language.service";
import { WebRTCService } from "app/services/web-rtc.service";

@Component({
    selector: 'app-avatar-video-presenter',
    templateUrl: './avatar-video.component.html',
    styleUrl: './avatar-video.component.scss'
})
export class AvatarVideoComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('avatarVideo') videoAnimation!: ElementRef;
    currentVideo: BehaviorSubject<string> = new BehaviorSubject<string>('');

    canVideoBePlayed: boolean = false;
    avatarBackground: any;
    langVideos: any[] = [];
    dIdVideo: any = null;

    backgroundSubscription!: Subscription;
    videoSubscription!: Subscription;
    dIdvideoSubscription!: Subscription;

    constructor(
        private config: ConfigService,
        private interaction: InteractionService,
        private language: LanguageService,
        public webrtc: WebRTCService
    ) {}

    ngOnInit(): void {
        this.backgroundSubscription = this.config.getPresenter().subscribe(background => {
            if (background) { this.avatarBackground = background;}
        });

        this.videoSubscription = this.config.getLangVideos().subscribe(async videos => {
            this.langVideos = videos;
            await this.prepareVideoPlayback("idle");
            this.playVideo();
        });

         this.dIdvideoSubscription = this.config.getDIdVideo().subscribe(async video => {
             this.dIdVideo = video;
             if (this.dIdVideo) {
                 await this.prepareVideoPlayback("d-id");
                 await this.waitForVideoReady();
                 this.interaction.isVideoPlaying.next(true);
             } else if (this.currentVideo.getValue() !== 'welcome') {
                 if (this.getVideoElement()){
                     this.getVideoElement().srcObject = null;
                     await this.onEnded()
                 }
             }
        });
    }

    async ngAfterViewInit() {
        if (!this.config.getFirstTime()) {
            await this.prepareVideoPlayback("welcome");
            this.config.setFirstOpen();
            this.interaction.isVideoPlaying.next(true);
        } else {
            await this.prepareVideoPlayback("idle");
        }

        await this.waitForVideoReady();
    }

    async waitForVideoReady() {
        await new Promise((resolve, _) => {
            setInterval(() => {
                if (this.canVideoBePlayed) resolve("");
            }, 100);
        });

        this.playVideo();
    }

    playVideo () {
        (this.getVideoElement()).play();
    }

    getVideo(video: string) {
        return this.langVideos.find(lv => lv.type === video);
    }

    getVideoElement() {
        return this.videoAnimation?.nativeElement;
    }

    async prepareVideoPlayback (video: string) {
        this.currentVideo.next(video);
        const element = this.getVideoElement();
        const videoObject = (video === "d-id") ? this.dIdVideo : this.getVideo(video);

        if (!videoObject) {
            console.error(`${video}: object is null or doesn't exist!`);
            return;
        } else {
            if (element) {
                if (video == "idle") {
                    element.muted = true;
                }

                if (video === 'd-id') {
                    element.muted = false;
                    element.src = null;
                    element.srcObject = videoObject;
                } else {
                    element.src = videoObject.source;
                }

                await new Promise(resolve => {
                    element.oncanplay = resolve;
                });
            }
        }
    }

    onLoadedMetadata() {
        this.canVideoBePlayed = true;
    }

    async onEnded () {
        await this.prepareVideoPlayback("idle");
        this.playVideo();
        this.interaction.isVideoPlaying.next(false);
    }

    ngOnDestroy(): void {
        this.videoSubscription.unsubscribe();
        this.backgroundSubscription.unsubscribe();
        this.dIdvideoSubscription.unsubscribe();
    }
}
